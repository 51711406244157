body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

strong,
b {
  font-weight: 500 !important;
}

.mdi-icon {
  fill: currentColor;
}

.hide {
  display: none !important;
}

fieldset > legend {
  margin-bottom: 8px;
}